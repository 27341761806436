body {
  margin: 0;

  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #282c34;
  color: white;
}

html {
  background-color: #282c34;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.name {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
  letter-spacing: 0.1em;
}

.name-head {
  letter-spacing: 0.4em;
}

.headings {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
  letter-spacing: 0.2em;
}

.subHeadings {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
    text-align: left;
}

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 640px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {

  .name-head {
    margin-top: 100px;
  }
}