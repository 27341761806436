.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.logo {
  height: 3.5vmin;
}
a {
  text-decoration: none;
  color: white;
  margin: 10px;
}
a:hover {
  color: white;
  text-decoration: none;
}

.navbar-custom {
  background-color: #282c34;
}
.custom-link {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.content {
  font-size: 18px;
  text-align: left;
}

.checkitout {
  /* position: absolute;
  top: 220px; */
  width: fit-content;
  margin: 20px;
}
.custom-link:hover {
  text-decoration: underline;
}

.project {
  height: auto;
  margin-bottom: 10px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.footer {
  width: 100%;
  font-size: 12px;
  color: white;
  display: inline-block;
  position: fixed;
  right: 0%;
  bottom: 0;
  padding: 10px;
}

.nav-centre-box {
  /* margin-left: 60px; */

  float: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.main-headings {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(2px + 2vmin);
  color: white;
  padding: 100px;
  /* padding-top: 100px; */
}

.custom-tab {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
    font-size: 15px;
}

#email-link .email {
  display: none;
}

#brand-logo:hover {
  text-decoration: none;
}

.hamButton {
  /* position: absolute;
  left: 50; */
}

.project-heading {
  margin-bottom: 10px;
}

.card-custom {
  background-color: #282c34;
  text-align: left;
  font-size: 15px;
  /* box-shadow: 10px 10px 5px grey; */
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  box-shadow: 0 15px 15px 0 rgba(0, 0, 0, 1), 0 10px 100px 0 rgba(0, 0, 0, 1);
}

.heading-content {
  text-align: left;
  font-size: 18px;
  font-family: "Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}

.coursework-edu {
  font-family: inherit;
}

#review-head {
  font-size: 18px;
}

.card-custom:hover {
  border: 1px solid white;
}

.oppfest {
  text-decoration: underline;
}

/* .card-body {
  background-color: #282c34;
  border: none;
}

.card-indi {
  margin: 0px;
  border: none;
} */
#email-link:hover .email {
  display: inline;
}

.email-id:hover {
  text-decoration: underline;
}

.App-link {
  color: #61dafb;
}

.navbar-toggler {
  /* width: 100%; */
  /* float: none;
  margin-right: 0; */
  position: absolute;
  top: 8px;
  right: 45%;
  border: none;
}

.contact {
  text-align: center;
  font-size: 18px;
}

.collapse-link {
  position: absolute;
  top: 12px;
  /* right: -60px; */
  right: -45px;
}

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 640px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {

  .collapse-link {
    position: absolute;
    top: 12px;
    /* right: -80px;
     */
    right: -65px;
  }

  .content {
    font-size: 18px;
    text-align: left;
  }

  .contact {
    font-size: 18px;
    text-align: center;
  }

  .main-headings {
    padding: 100px 35px 35px 35px;
  }
}

@media only screen and (max-width: 600px) {
  .content {
    font-size: 18px;
    text-align: left;
  }

  .contact {
    text-align: center;
    font-size: 18px;
  }

  .main-headings {
    padding: 100px 35px 35px 35px;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}